/**
 * Utils
 */

import axios from './axios';
import storage from './storage';

export {
  axios,
  storage
};
