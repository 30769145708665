/* eslint-disable max-len */
import abilities from './abilities';
import auth from './auth';
import instances from './instances';
import groups from './groups';
import notifications from './notifications';
import users from './users';
import stats from './stats';
import host from './host';
import requests from './requests';
import appconfig from './appconfig';
import checkconfig from './checkconfig';
import images from './images';
import operations from './operations';
import networks from './networks';
import profiles from './profiles';
import projects from './projects';
import servers from './servers';

export default {
  abilities,
  auth,
  instances,
  groups,
  notifications,
  users,
  stats,
  host,
  requests,
  checkconfig,
  appconfig,
  images,
  operations,
  networks,
  profiles,
  projects,
  servers
};
