// import { axios } from '../utils';

const state = {
  initiliazed: false,
  dialog: {
    active: false,
    instanceId: null
  }
};

export default state;
